<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">
            <i class="fas fa-user margin-r" aria-hidden="true"></i>Members
          </h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between">
                  <h2 class="card-title ">
                    <button class="button-1 w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Invite Members</button>
                  </h2>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">New Member</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <form ref="MemberForm" @submit.prevent="PostAddMember()" method="post" class="row" align="left">
                            <div class="col-md-12">
                              <label for="validationDefault08" class="form-label">Email</label>
                              <Multiselect v-model="MultiSelectEmails.value" v-bind="MultiSelectEmails"></Multiselect>
                              <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                                <strong>Tips!</strong><small> Enter the email address of  member you wish to add to your organization and then press space or enter to add.</small>
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label for="role" class="form-label">Role</label>
                              <select class="form-select" v-model="MemberPost.type" required>
                                <option selected disabled value="">Select role</option>
                                <option value="1">Admin</option>
                                <option value="2">Worker</option>
                                <option value="3">Project Manager</option>
                              </select>
                            </div>
                            <div class="col-md-12 mt-3"></div>
                            <div class="modal-footer">
                              <button :disabled="Form.submit" type="submit" class="button-1 btn btn-primary">Invite Member</button>
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabla-->
              <div class="table-responsive">
                <table id="member-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Email</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Role</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Status</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListMembers.status != 'false'" v-for="(obj, key, index) in ListMembers.list" :key="index">
                      <td class="text-start">
                         <span style="width: 40px; position: relative;">
                            <img :src="obj.picture" alt="" width="38" height="38" class="img-avatar">                        
                             <span v-bind:style="{ backgroundColor: obj.connection.color}" class="dot"></span>
                        </span>  
                        <small style="font-size:10px; margin:10px">{{obj.connection.description}}</small> {{obj.email}} 
                        <!-- prueba -->
                          
                        <!-- end prueba -->
                      </td>
                      <td class="text-start">{{obj.description}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Active</p>
                        <div>
                          <p v-if="obj.status == '0'" class="special-badget-status" style=" background-color: #dee2e6;display:inline-flex;">Inactive</p>
                          <!-- <small v-if="obj.status == '0'"> Waiting for the user to verify their email address.</small> -->
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                            <li>
                              <router-link :to="'member/edit/'+obj.id_session" class="dropdown-item rounded">Edit Member</router-link>
                            </li>
                            <li>
                              <a v-if="obj.status == '1'" class="dropdown-item" v-on:click="UpdateMemberStatus(obj.id,obj.status)">Inactivate Member</a>
                              <a v-if="obj.status == '0'" class="dropdown-item" v-on:click="UpdateMemberStatus(obj.id,obj.status)">Active Member</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 
  import Multiselect from '@vueform/multiselect'

  export default
  {
    name: 'ContentMember',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      MultiSelectEmails: 
      {
    	  mode: 'tags',
        value: [],
        closeOnSelect: false,
        options: [{ disabled:true }],
        searchable: true,
        createTag: true,
        inputType: "email",
        required: true,
        placeholder: "example@domain.com",
        addTagOn: ['enter','space'],
        hideSelected: true,
        showOptions : false
      },
      MemberPost: 
      {
        email: "",
        type: "",
        status_post: "",
        message:""  
      },
      ListMembers: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false
      },
    }),
    methods: 
    {
      PostAddMember() 
      {
        this.Form.submit = true 
        this.MemberPost.email = this.MultiSelectEmails.value

        Api.post("/member/insert",this.MemberPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.MemberPost.status_post = result.data.status
            this.MemberPost.message = result.data.msg;

            var table = $('#member-table').DataTable()
            table.destroy()

            this.$moshaToast( ''+this.$filters.OnlyLetters(this.MemberPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            this.$nextTick(function() 
            {
              setTimeout( () => 
              {
                this.MultiSelectEmails.value = []
                this.MemberPost.message = ""
                this.MemberPost.status_post = ""  
              }, 3000);

              this.Form.submit = false  
              this.GetMember()
            })
          }
          
          if(result.data.status == "false")
          {
            setTimeout( () => 
            {
              this.ProjectPost.message = ""
              this.ProjectPost.status_post = ""
            }, 3000);

            this.ProjectPost.status_post = result.data.status
            this.ProjectPost.message = result.data.msg;
          }
        })    
      },
      GetMember() 
      {
        NProgress.start()
        Api.post("/member/list",'').then((result) => 
        {
          this. ListMembers.status = result.data.status

          if(this. ListMembers.status == "true")
          {
            NProgress.done()
            this. ListMembers.list = result.data.member;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      UpdateMemberStatus(id,status) 
      {
        if(status==1) { var MemberStatus=0 }
        if(status==0) { var MemberStatus=1 }

        let Post = 
        {
          id:id,
          status:MemberStatus
        };

        Api.post("/member/status", Post).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.RefreshDatatable()
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#member-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/member/list",'').then((result) => 
        {
          this.ListMembers.status = result.data.status

          if(this.ListMembers.status == "true")
          {
            this. ListMembers.list = result.data.member;
          }
        })
      }
    },
    mounted()
    {
      this.GetMember()
    }
  }
</script>

<style>
.dot 
{
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border: 2px solid white;
  bottom: -12px;
  right: -3px;
  border-radius: 50%;
  position:absolute;

}
  .mosha__toast__content__text 
  {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 700;
    white-space:pre;
  }
</style>
